import React, { useState, useEffect } from 'react';
import teamData from "./team.json";
import './Teams.css';
import linkedin from "../Assests/Images/linkedin1.png";

const Team = () => {

    return (
        <div className="responsive-container-block container">
            <p className="text-blk team-head-text">Team</p>
            <div className="responsive-container-block">
                {teamData.map((member, index) => (
                    <div key={index} className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12 card-container">
                        <div className="card">
                            <div className="team-image-wrapper">
                                <img className="team-member-image" src={process.env.PUBLIC_URL + member.image} alt={member.name} />
                            </div>
                            <p className="text-blk name">{member.name}</p>
                            <p className="text-blk position">{member.position}</p>
                            <div className="social-icons">
                                <a href={member.social.twitter} target="_blank" rel="noopener noreferrer">
                                    <img className="twitter-icon" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/Icon.svg" alt="Twitter" />
                                </a>
                                <a href={member.social.linkedin} target="_blank" rel="noopener noreferrer">
                                    <img className="linkedin-icon" style={{ color: "blue" }} src="https://img.icons8.com/color/22/linkedin.png" alt="Linkedin" />
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Team;
