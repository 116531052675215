import React from 'react';
import './styles.css';

const FloatingImages = ({ products }) => {
    return (
        <div className="image-slider">
            {products.map((product, index) => (
                <img
                    key={index}
                    src={process.env.PUBLIC_URL + product.thumbnail}
                    alt=""
                    className="slide"
                />
            ))}
        </div>
    );
};

export default FloatingImages;
