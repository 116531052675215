import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Buttons from './Button';
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.4);
`;
const Slide = styled.div`
  display: flex;
  transition: transform 0.6s ease-in-out;
  transform: ${props => `translateX(${props.xPosition}px)`}; // (*)
img {
    width: 100%;
  }
`;
function Carousel({
    images,
    setWidth,
    xPosition,
    handleClickPrev,
    handleClicknext,
}) {
    const slideRef = useRef();
    useEffect(() => {
        if (slideRef.current) {
            const width = slideRef.current.clientWidth;
            setWidth(width);
        }
    }, [setWidth]);
    return (
        <Wrapper>
            <Slide xPosition={xPosition} ref={slideRef}>
                {images.map((img, i) => (
                    <img src={process.env.PUBLIC_URL + img} alt="" key={i} />
                ))}
            </Slide>
            {/* <Buttons
                handleClickPrev={handleClickPrev}
                handleClicknext={handleClicknext}
            /> */}
        </Wrapper>
    );
}
export default Carousel;