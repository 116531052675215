import "./App.css";

import Loader from "./Components/Loader/Loader";
import Footer from "./Components/Footer/Footer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Changed import statement
import Navbar from "./Components/Navbar/Navbar";

import Main from "./Components/Main/Main";

import React, { useState, useEffect } from "react";
import Layout from "./Components/Buynow/Layout";
import Teams from "./Components/shubhansh files/TeamSection";
import SpeakerProposal from "./Components/shubhansh files/SpeakerProposal";
import FloatingImages from "./pages/Gallery/gallery";
import Tickets from "./Components/shubhansh files/Tickets";

function App() {
  const [showLoader, setShowLoader] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const productsData = [
    { title: "", link: "", thumbnail: "/assets/gallery/img1.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img3.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img4.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img5.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img6.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img7.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img8.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img9.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img10.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img11.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img12.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img13.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img14.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img15.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img16.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img17.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img18.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img19.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img20.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img21.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img22.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img23.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img24.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img25.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img26.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img27.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img28.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img29.jpg" },
    { title: "", link: "", thumbnail: "/assets/gallery/img30.jpg" },
  ];

  useEffect(() => {
    // Simulate loading time for 5 seconds
    const loadingTimeout = setTimeout(() => {
      setIsLoaded(true);
      setShowLoader(false);
    }, 3000);

    // Check if content is loaded before the timeout
    window.onload = () => {
      setIsLoaded(true);
      // clearTimeout(loadingTimeout); // Cancel the timeout if content is loaded
    };

    // Clean up
    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <div className="App">
      {showLoader && <Loader />}{" "}
      {/* Show Loader component if showLoader is true */}
      {!isLoaded && !showLoader && <Loader />}{" "}
      {/* Show Loader component until resources are loaded */}
      {isLoaded && !showLoader && (
        <div className="hey">
          <Router>
            <Navbar />
            <Layout>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route
                  path="/gallery"
                  element={<FloatingImages products={productsData} />}
                />
                <Route path="/speaker-proposal" element={<SpeakerProposal />} />
                <Route path="/tickets" element={<Tickets />} />
                <Route path="/teams" element={<Teams />} />
              </Routes>
            </Layout>
            <Footer />
          </Router>
        </div>
      )}
    </div>
  );
}

export default App;
