import React from 'react';
import "./Buy.css";
import buytick from "../Assests/Images/ticket.png";

const Buy = () => {
  return (
    <div className='buy'>
      <a href='https://konfhub.com/checkout/tedxlnct?ticketId=15005' target="_blank" rel="noreferrer"><p className='buynowbut'>
        <img className="buyimg" src={buytick} alt='' />
      </p> </a>
    </div>
  );
};

export default Buy;
