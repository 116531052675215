import { Breakpoint, BreakpointProvider } from "react-socks";
import StickyScroll2 from "./mobile/faq-mobile";
import StickyScroll from "./faq";

const FaqPage = () => {
  const content = [
    {
      title: "What is TEDx?",
      description:
        "TEDx is a localized version of TED talks, organized independently in various communities worldwide, featuring speakers sharing impactful ideas on diverse topics.",
    },
    {
      title: "How can I register for TEDxLNCT Bhopal?",
      description:
        "Registration details for TEDxLNCT Bhopal is live, click on the tickets section.",
    },
    {
      title: "How can I stay updated about TEDxLNCT Bhopal?",
      description:
        "Stay informed about TEDxLNCT Bhopal by following us on Instagram, joining our WhatsApp group, or subscribing to our email updates.",
    },
    {
      title: "How do I apply to speak at TEDxLNCT Bhopal?",
      description:
        "To become a speaker at TEDxLNCT Bhopal, submit your portfolio to tedx.organiser@lnct.ac.in.",
    },
    {
      title: "Can I join as a sponsor at TEDxLNCT?",
      description:
        "Absolutely! We welcome sponsorship inquiries. If you're interested in partnering with TEDxLNCT, please reach out to us via email at tedx.organiser@lnct.ac.in with your sponsorship proposal. We're excited to explore collaboration opportunities with you.",
    },
    {
      title: "How can I become a media partner?",
      description:
        "If you're interested in becoming a media partner for TEDxLNCT Bhopal, please contact us to discuss potential partnership opportunities and collaborations.",
    },
  ];

  return (
    <BreakpointProvider>
      <Breakpoint customQuery="(max-width: 1025px)">
        <StickyScroll2 content={content} />
      </Breakpoint>
      <Breakpoint customQuery="(min-width: 1025px)">
        <StickyScroll content={content} />
      </Breakpoint>
    </BreakpointProvider>
  );
};

export default FaqPage;
