import React from 'react';
import './Footer.css'
import logo from "../Assests/Images/TEDxlogo.png"



export default function Footer() {

  return (
    <div className='footer' id='footer'>
      <div className='fcontainer'>
        <div className='fcontent'>
          <div className='footerlinks'>
            <h4 className='redtext'>GET IN TOUCH</h4>
            <p >
              E-mail: &nbsp;
              <a href="mailto:tedx.organiser@lnct.ac.in" style={{ color: "#ffffff" }}>
                tedx.organiser@lnct.ac.in
              </a>
            </p>
            <h4 className='redtext'>GET UPDATED</h4>
            <div className='socialmedia'>
              <a href="https://instagram.com/tedxlnct" target='_blank' rel="noreferrer">
                <img alt="Instagram " style={{ width: '34px', height: '34px' }} fetchpriority="high" src="https://static.wixstatic.com/media/11062b_cef3b719166a4815b446d4dcfcb6120d~mv2.png/v1/fill/w_45,h_45,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_cef3b719166a4815b446d4dcfcb6120d~mv2.png" />
              </a>
              <a href="https://chat.whatsapp.com/KOjJOUl73hZKXlMJxLfcs1" target='_blank' rel="noreferrer"><img alt="Facebook" style={{ width: '34px', height: '34px', color: "white" }} fetchpriority="high" src={process.env.PUBLIC_URL + '/assets/other/wa.png'} /></a>
              <a href="https://twitter.com/TEDx_LNCTBhopal" target='_blank' rel="noreferrer"><img alt="Twitter" style={{ width: '34px', height: '34px' }} fetchpriority="high" src="https://static.wixstatic.com/media/11062b_8616df6eb29e41d9a61d3677e7ace261~mv2.png/v1/fill/w_45,h_45,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_8616df6eb29e41d9a61d3677e7ace261~mv2.png" /></a>
              <a href="https://www.linkedin.com/company/tedxlnctbhopal/" target='_blank' rel="noreferrer"><img alt="LinkedIn" style={{ width: '34px', height: '34px' }} fetchpriority="high" src="https://static.wixstatic.com/media/11062b_b5690303a8ff470e9b1c03a24aff140b~mv2.png/v1/fill/w_45,h_45,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_b5690303a8ff470e9b1c03a24aff140b~mv2.png" /></a>
            </div>


          </div>
          <div className='footerlinks'>
            <h4 className='button blacktext'><a className='contact-g' href="mailto:tedx.organiser@lnct.ac.in">PARTNER WITH US</a> </h4>
          </div>
          <div className='footerlinks'>
            <div className='Nav-logo'>
              <img className="tedx-logo" src={logo} alt='' />
            </div>
          </div>
        </div>
        <div className='copyright'>
          <p>© Copyright by TEDxLNCT. This is independent TEDx events, operated under license from TED. All Rights Reserved. &nbsp;
          </p>

        </div>
      </div>
    </div>
  )
}
