import React from 'react'
import HeroPg from "../HeroPg/HeroPg"
import BlockFeatures from '../thoughtloom/Thoughtloom.tsx'
import FaqPage from '../../pages/questions/faqSection'
import Tickets from '../shubhansh files/Tickets.jsx'
import { useMediaQuery } from 'react-responsive';
import Speakersm from '../Speakers-mobile/Speakersm.jsx'
import Speakers from '../Speakers-mobile/Speakers.tsx'
import HomeGallery from '../Speakers-mobile/HomeGallery.jsx'
import { useRef } from "react";

const Main = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const speakerRef = useRef(null);

  return (
    <div>
      <HeroPg />
      {!isMobile && (
        <BlockFeatures />
      )}
      {/* {isMobile && (
        <Tickets />
      )} */}
      {/* <Speakers /> */}
      <div ref={speakerRef} id='speakerSection'>
        <Speakersm />
      </div>
      {/* <HomeGallery /> */}
      {/* <InstagramFeed /> */}
      <FaqPage />

    </div>
  )
}

export default Main