import React, { useEffect } from "react";
import {
    Box,
    Link as ChakraLink,
    Grid,
    Image,
    Text,
    Button,
    useColorModeValue,
} from "@chakra-ui/react";
import { GiTalk } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";

import ted from "../../assets/images/ted.webp";
import "../../global.module.css";
// import "../../normalize.module.css";

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const featuresData = [
    {
        icon: GiTalk,
        iconColor: "var(--color-primary)",
        title: "Propose a TEDxLNCT Talk",
        description: "Do you have a new and interesting idea worth spreading? Would you like to speak at TEDxLNCT? Like TED itself, TEDxLNCT is focused on Ideas Worth Spreading, placing the emphasis on the idea. If you have an idea that could inspire the TEDxLNCT audience, we would love to have you submit your idea to us for consideration for a TEDxLNCT conference. If you know someone else with an idea worth spreading, please direct them to this page on our Web site and encourage them to submit their idea.",
        linkText: "exploreMore",
        linkUrl: "https://www.ted.com/about/our-organization",
        image: {
            src: ted,
            alt: "easy",
        },
        size: "40px",
    }
];

interface FeatureProps {
    // icon: React.ElementType;
    // iconColor: string;
    title: string;
    description: string;
    linkText: string;
    linkUrl: string;
    image: {
        src: string;
        alt: string;
        // srcSetWebp: string;
        // srcSetPng: string;
    };
    size: string;
}

const Feature: React.FC<FeatureProps> = ({
    // icon: IconComponent,
    // iconColor,
    title,
    description,
    linkText,
    linkUrl,
    image,
    size,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        return () => {
            ScrollTrigger.getAll().forEach(t => t.kill());
        };
    }, []);

    return (
        <Box
            className="feature__content"
        // border={"1px solid red"}
        // padding="2rem"
        >
            {/* <Box
      className="icon-container"
      background="#f3f9fa"
      width="64px"
      height="64px"
      borderRadius="100%"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
    >
      <IconComponent
        className="icon icon--primary"
        boxSize={size}
        color={iconColor}
      />
    </Box> */}
            <Text
                className="h3 feature__heading"
                // color="var(--color-white)"
                color={useColorModeValue("white", "white")}
                fontSize={{ base: "2.5rem", lg: "2.5rem" }}
                fontWeight="bold"
                lineHeight="1.3"
                margin="1rem 0"
            >
                {title}
            </Text>
            <Text
                className="p"
                marginTop="0"
                marginBottom="30px"
                color={useColorModeValue("gray.400", "gray.400")}
            // textAlign={"justify"}
            // fontSize={{ base: "1.2rem", lg: "1.2rem" }}
            // marginBottom={"1.2rem"}
            >
                {t(description)}
            </Text>
            <a className='contact-g' href="mailto:tedx.organiser@lnct.ac.in">
                <Button color={"white"} fontSize={"2rem"} border={"1px"} variant='outline' width="30rem" height={"6rem"}>
                    Submit a Talk
                </Button>
            </a>
            {/* <ChakraLink
        className="link-arrow"
        href={linkUrl}
        fontSize={{ base: "1.2rem", lg: "1.2rem" }}
        fontWeight="bold"
        color={useColorModeValue("tomato", "tomato")}
        textTransform="uppercase"
        target="_blank"
        _hover={{
          "::after": {
            marginLeft: "10px",
          },
        }}
        sx={{
          "::after": {
            content: '">"',
            marginLeft: "5px",
            transition: "margin 0.15s",
          },
        }}
      >
        {t(linkText)}
      </ChakraLink> */}
        </Box>
    );
};

interface FeatureImageProps {
    image: {
        src: string;
        alt: string;
        // srcSetWebp: string;
        // srcSetPng: string;
    };
}

const FeatureImage: React.FC<FeatureImageProps> = ({ image }) => (
    <Box as="picture">
        {/* <source type="image/webp" srcSet={image.srcSetWebp} />
    <source type="image/png" srcSet={image.srcSetPng} /> */}
        <Image
            className="feature__image"
            src={image.src}
            alt={image.alt}
            width="100%"
            borderRadius={"5%"}
            boxShadow="0 0 30px 1px black"
        />
    </Box>
);

const SpeakerProposal: React.FC = () => {
    useEffect(() => {
        window.onload = () => {
            AOS.init({
                duration: 800,
                easing: "ease-in-out",
            });
        };
    }, []);

    const { t } = useTranslation();

    return (
        <Box
            className="block block--dark aos-animate"
            // bg={useColorModeValue("#F5DEB3	", "gray.800")}
            bg={useColorModeValue("gray.800", "gray.800")}
            width="100%"
            overflow={"hidden"}
        // clipPath="polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%)"
        >
            <Box
                className="block container"
                padding="6rem 2rem"
                maxWidth="114rem"
                margin="0 auto"
                textAlign={"left"}
                alignItems={"left"}
            >
                <Box
                    className="block__header aos-animate"
                    textAlign="center"
                    // marginBottom="-4rem"
                    data-aos="fade-up"
                    data-aos-duration="500"
                // marginEnd={"1rem"}
                >
                    <Text
                        className="h2 block__heading"
                        color={useColorModeValue("#E1DCA9", "#E1DCA9")}
                        marginBottom="1rem"
                        marginTop={"4rem"}
                        fontSize={{ base: "5.5rem", lg: "7rem" }}
                        fontWeight="bold"
                        lineHeight="1.1"
                    >
                        Do you have a idea worth spreading?
                    </Text>
                    {/* <Text
                        className="p"
                        color={useColorModeValue("silver", "silver")}
                        fontSize={{ base: "2.0rem", lg: "2.8rem" }}
                        // fontWeight="bold"
                        marginBottom="2rem"
                    >
                        {t("featureSubTitle")}
                    </Text> */}
                </Box>
                {featuresData.map((feature, index) => (
                    <Box
                        key={index}
                        className={`grid grid--1x2 feature feature-${index % 2 === 0 ? "even" : "odd"
                            }`}
                    >
                        <Grid
                            display="grid"
                            templateColumns={["1fr", null, null, "repeat(2, 1fr)"]}
                            gap="4rem 2rem"
                            margin="4rem 0"
                            sx={{
                                order: index % 2 === 0 ? 1 : 2,
                                // textAlign: index % 2 === 0 ? "right" : "left",
                            }}
                        >
                            {index % 2 === 0 ? (
                                <>
                                    <Box
                                        className="aos-animate"
                                        data-aos="fade-right"
                                        data-aos-duration="500"
                                    >
                                        <Feature {...feature} size={feature.size} />
                                    </Box>
                                    <Box
                                        className="aos-animate"
                                        data-aos="fade-left"
                                        data-aos-duration="500"
                                    >
                                        <FeatureImage image={feature.image} />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box
                                        className="aos-animate"
                                        data-aos="fade-right"
                                        data-aos-duration="500"
                                    >
                                        <FeatureImage
                                            image={feature.image}
                                            data-aos-duration="500"
                                        />
                                    </Box>
                                    <Box
                                        className="aos-animate"
                                        data-aos="fade-left"
                                        data-aos-duration="500"
                                    >
                                        <Feature {...feature} size={feature.size} />
                                    </Box>
                                </>
                            )}
                        </Grid>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default SpeakerProposal;
