// import React, { useState, useEffect, useCallback } from 'react';
// import Carousel from './Carousel';
import styled from 'styled-components';

// const Wrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #eaeaea;
// `;
// export default function Speakersm() {
//   const [index, setIndex] = useState(0);
//   const [width, setWidth] = useState(0);
//   const [xPosition, setXPosition] = useState(0);
//   const images = [
//     // "./assets/speakers/Yogesh_sir_post03.png",
//     "./assets/speakers/Alvin_sir_post.png",
//     "./assets/speakers/Anooja_mam_post_grid.png",
//     "./assets/speakers/Naman_sir_post_grid.png",
//     "./assets/speakers/Padmini_mam_post.png",
//     "./assets/speakers/Rahul_sir_post.png",
//     "./assets/speakers/Sonia_mam_post_grid.png",
//     "./assets/speakers/Tushar_sir_post_grid.png",
//   ];

//   const handleClickPrev = () => {
//     if (index === 0) return;
//     setIndex(index - 1);
//     setXPosition(xPosition + width);
//   };
//   const handleClicknext = useCallback(() => {
//     if (index === images.length - 1) {
//       setIndex(0);
//       setXPosition(0);
//     } else {
//       setIndex(index + 1);
//       setXPosition(xPosition - width);
//     }
//   });
//   useEffect(() => {
//     const handleAutoplay = setInterval(handleClicknext, 2000);
//     return () => clearInterval(handleAutoplay);
//   }, [handleClicknext]);
//   return (
//     <div className="bg-gray-100">
//       <div className="container mt-0 flex justify-center mx-auto pt-16 bg-gray-100">
//         <div>
//           {/* <p className="text-gray-500 text-lg text-center font-normal pb-3">BUILDING TEAM</p> */}
//           <h1 className="xl:text-4xl text-3xl text-center bg-gray-100 text-gray-800 font-extrabold pb-6 sm:w-4/6 w-5/6 mx-auto">Speakers TedX LNCT 2024</h1>
//         </div>
//       </div>
//       {/* <div className="w-full bg-gray-100 px-10 pt-10">
//         <div className="container mx-auto"> */}
//       <Wrapper className="App">
//         <Carousel
//           images={images}
//           setWidth={setWidth}
//           xPosition={xPosition}
//           handleClickPrev={handleClickPrev}
//           handleClicknext={handleClicknext}
//         />
//       </Wrapper>
//       {/* </div>
//       </div> */}
//     </div >
//   );
// }



import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Link as ChakraLink,
  Grid,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { GiTalk } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";


import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Carousel from './Carousel';



const Speakersm = () => {
  const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
`;

  const [index, setIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const [xPosition, setXPosition] = useState(0);
  const images = [
    // "./assets/speakers/Yogesh_sir_post03.png",
    "./assets/speakers/Alvin_sir_post.png",
    "./assets/speakers/Anooja_mam_post_grid.png",
    "./assets/speakers/Naman_sir_post_grid.png",
    "./assets/speakers/Padmini_mam_post.png",
    "./assets/speakers/Rahul_sir_post.png",
    "./assets/speakers/Sonia_mam_post_grid.png",
    "./assets/speakers/Tushar_sir_post_grid.png",
  ];

  const handleClickPrev = () => {
    if (index === 0) return;
    setIndex(index - 1);
    setXPosition(xPosition + width);
  };
  const handleClicknext = useCallback(() => {
    if (index === images.length - 1) {
      setIndex(0);
      setXPosition(0);
    } else {
      setIndex(index + 1);
      setXPosition(xPosition - width);
    }
  });
  useEffect(() => {
    const handleAutoplay = setInterval(handleClicknext, 2000);
    return () => clearInterval(handleAutoplay);
  }, [handleClicknext]);

  useEffect(() => {
    window.onload = () => {
      AOS.init({
        duration: 800,
        easing: "ease-in-out",
      });
    };
  }, []);

  const { t } = useTranslation();

  return (
    <Box
      className="block block--dark aos-animate"
      // bg={useColorModeValue("#F5DEB3	", "gray.800")}
      bg={useColorModeValue("whitesmoke", "whitesmoke")}
      width="100%"
      overflow={"hidden"}
    // clipPath="polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%)"
    >
      <Box
        className="block container"
        padding="6rem 2rem"
        maxWidth="114rem"
        margin="0 auto"
        textAlign={"left"}
        alignItems={"left"}
      >
        <Box
          className="block__header aos-animate"
          textAlign="center"
          // marginBottom="-4rem"
          data-aos="fade-up"
          data-aos-duration="500"
        // marginEnd={"1rem"}
        >
          <Text
            className="h2 block__heading"
            color={useColorModeValue("#000000", "#000000")}
            marginBottom="1rem"
            marginTop="0"
            fontSize={{ base: "5.5rem", lg: "7rem" }}
            fontWeight="bold"
            lineHeight="1.1"
          >
            {"Speakers"}
          </Text>
          <Text
            className="p"
            color={useColorModeValue("#000000", "#000000")}
            fontSize={{ base: "2.0rem", lg: "2.8rem" }}
            // fontWeight="bold"
            marginBottom="2rem"
          >
            {"TEDxLNCT 2024"}
          </Text>
        </Box>


      </Box>
      <Wrapper className="App">
        <Carousel
          images={images}
          setWidth={setWidth}
          xPosition={xPosition}
          handleClickPrev={handleClickPrev}
          handleClicknext={handleClicknext}
        />
      </Wrapper>
    </Box>
  );
};

export default Speakersm;
