import React, { useRef, useState, useEffect } from 'react';
import "./Navbar.css";
import logoblack from '../Assests/Images/logo-black.png';
import Hamburger from 'hamburger-react';
import { Link, useLocation } from 'react-router-dom';

import logowhite from "../Assests/Images/logo-white.png"
const Navbar = () => {
  const [menu, setMenu] = useState(() => {
    // Get the menu item from local storage, if it exists
    return localStorage.getItem('selectedMenu') || "home";
  });
  const menuRef = useRef();
  const [isOpen, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const location = useLocation();

  const [navTextColor, setNavTextColor] = useState('#000'); // Default text color
  const [navLogo, setNavLogo] = useState(logoblack); // Default logo

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Update menu state when location changes
    setMenu(location.pathname.slice(1) || "home");
  }, [location]);

  useEffect(() => {
    // Save selected menu item to local storage
    localStorage.setItem('selectedMenu', menu);
  }, [menu]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 800) {
        // Set initial state based on location
        if (location.pathname.includes('gallery')) {
          setNavTextColor('#fff'); // Set text color to white for gallery page
          setNavLogo(logowhite); // Set logo to the white logo for gallery page
          // } else if (location.pathname.includes('speaker-proposal')) {
          //   setNavTextColor('#fff'); // Set text color to white for sponsors page
          //   setNavLogo(logowhite); // Set logo to the white logo for sponsors page
        } else if (location.pathname.includes('teams')) {
          setNavTextColor('#fff'); // Set text color to white for sponsors page
          setNavLogo(logowhite); // Set logo to the white logo for sponsors page
        }
        // else if (location.pathname.includes('tickets')) {
        //   setNavTextColor('#fff'); // Set text color to white for sponsors page
        //   setNavLogo(logowhite); // Set logo to the white logo for sponsors page
        // } 
        else {
          setNavTextColor('#000'); // Set default text color
          setNavLogo(logoblack); // Set default logo
        }
      }
    }

    // Call handleResize initially
    handleResize();

    // Add event listener to listen for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [location]);

  const handleHamburgerClick = () => {
    setOpen(!isOpen);

    if (!isOpen) {
      menuRef.current.style.display = 'flex';
    } else {
      menuRef.current.style.display = 'hidden';
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  //our team handler and sponsors handler
  // it reloads

  // const [reloadPage, setReloadPage] = useState(false);

  const handleReload = () => {
    window.scrollTo(0, 0);
  };


  const handleMenuItemClick = (menuItem) => {
    setMenu(menuItem);
    setOpen(false);
    scrollToTop();


    //change Navbar color

    if (menuItem === 'gallery') {
      setNavTextColor('#fff'); // Change text color to white
      setNavLogo(logowhite); // Change logo to the gallery logo
    }
    else if (menuItem === 'teams') {
      setNavTextColor('#fff'); // Change text color to white
      setNavLogo(logowhite);
    }
    // else if (menuItem === 'ticket') {
    //   window.open('https://konfhub.com/checkout/tedxlnct?ticketId=15005', '_blank');
    // }
    else {
      setNavTextColor('#fff'); // Reset text color to default
      setNavLogo(logowhite); // Reset logo to the default logo
    }
  };
  const hamburgerColor = isOpen ? '#fff' : (location.pathname.includes('gallery') || location.pathname.includes('teams') || location.pathname.includes('speaker-proposal') ? '#fff' : '#000');
  return (
    <div className="navbar">
      <div className='Nav-logo'>
        <Link to='/'>
          <img className="tedx-logo" src={navLogo} alt='' />
        </Link>
        {isMobile && (
          <div className="fixed-hamburger">
            <Hamburger size={44} color={hamburgerColor} toggled={isOpen} toggle={handleHamburgerClick} />
          </div>
        )}
      </div>

      <ul ref={menuRef} className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <li onClick={() => handleMenuItemClick("home")} key="home" style={{ color: menu === "home" ? '#eb0028' : navTextColor, transition: "0.5s ease-out" }}><Link style={{ textDecoration: 'none', color: menu === "home" ? '#eb0028' : navTextColor, transition: "1s ease-out" }} to='/'><span style={{ color: menu === "home" ? '#eb0028' : "", transition: "0.5s ease-out" }}>Home</span></Link> {menu === "home" ? <hr /> : <></>} </li>
        <li key="speaker" className='speaker' style={{ color: menu === "speaker" ? '#eb0028' : navTextColor, transition: "1s ease-out", cursor: 'pointer' }}><span style={{ color: menu === "speaker" ? '#eb0028' : "", transition: "0.5s ease-out" }}><a href={'#speakerSection'}>Speakers</a></span> {menu === "speaker" ? <hr /> : <></>}</li>

        {/* <li onClick={() => handleMenuItemClick("speaker-proposal")} key="speaker-proposal" style={{ color: menu === "speaker-proposal" ? '#eb0028' : navTextColor, transition: "0.5s ease-out" }}><Link style={{ textDecoration: 'none', color: menu === "speaker-proposal" ? '#eb0028' : navTextColor, transition: "1s ease-out" }} to='/speaker-proposal'><span style={{ color: menu === "speaker-proposal" ? '#eb0028' : "", transition: "0.5s ease-out" }}>Speaker Proposal</span></Link> {menu === "speaker-proposal" ? <hr /> : <></>}</li> */}
        {/* <li onClick={() => handleMenuItemClick("ticket")} key="ticket" style={{ color: menu === "ticket" ? navTextColor : navTextColor, transition: "0.5s ease-out" }}><Link style={{ textDecoration: 'none', color: menu === "home" ? navTextColor : navTextColor, transition: "1s ease-out" }} to='/' ><span style={{ color: menu === "home" ? '' : "", transition: "0.5s ease-out" }}>Tickets</span></Link> {menu === "home" ? <></> : <></>} </li> */}

        <li onClick={() => handleMenuItemClick("gallery")} key="gallery" style={{ color: menu === "gallery" ? '#eb0028' : navTextColor, transition: "0.5s ease-out" }}><Link style={{ textDecoration: 'none', color: menu === "gallery" ? '#eb0028' : navTextColor, transition: "1s ease-out" }} to='/gallery'><span style={{ color: menu === "gallery" ? '#eb0028' : "", transition: "0.5s ease-out" }}>Gallery</span></Link> {menu === "gallery" ? <hr /> : <></>}</li>
        <li onClick={() => handleMenuItemClick("teams")} key="teams" style={{ color: menu === "teams" ? '#eb0028' : navTextColor, transition: "0.5s ease-out" }}><Link style={{ textDecoration: 'none', color: menu === "teams" ? '#eb0028' : navTextColor, transition: "1s ease-out" }} to='/teams'><span onClick={handleReload} style={{ color: menu === "teams" ? '#eb0028' : "", transition: "0.5s ease-out" }}>Team</span> </Link>{menu === "teams" ? <hr /> : <></>}</li>
        <li key="contact" className='contact' onClick={() => { window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }); setOpen(false); }} style={{ color: menu === "contact" ? '#eb0028' : navTextColor, transition: "1s ease-out", cursor: 'pointer' }}><span style={{ color: menu === "contact" ? '#eb0028' : "", transition: "0.5s ease-out" }}>Contact Us</span> {menu === "contact" ? <hr /> : <></>}</li>
      </ul>
    </div>
  );
}

export default Navbar;
