import React from 'react';
import './loader.css';
import loader from "../Assests/Images/ted_loader.mp4";

export default function Loader({ isLoading }) {
  return (
    // <div className={`loader-container ${isLoading ? '' : 'loading'}`}>
    // <div className={`loading-icon ${isLoading ? '' : 'zoom'}`}>
    // {/* <img src={loader} alt="Loading icon" width="250" className='svg-icon' /> */ }
    < video autoplay="autoplay" muted width={"100%"} height={"100%"} className="svg-icon" >
      < source src={loader} type="video/mp4" />
    </video >
    // </div>
    //</div>
  );
}
